import { FunctionComponent, useCallback } from "react"
import cn from "classnames"
import { useTheme } from "next-themes"

import styles from "./styles.module.scss"

interface ToggleProps {
  isFirstActive: boolean
  onToggle: Function
  className: string
  resolvedTheme?: string
}

const Toggle: FunctionComponent<ToggleProps> = ({
  isFirstActive,
  onToggle,
  className,
  resolvedTheme = "light",
}) => {
  const handleClickFirst = useCallback(() => {
    onToggle(true)
  }, [onToggle])

  const handleClickSecond = useCallback(() => {
    onToggle(false)
  }, [onToggle])

  return (
    <div
      className={cn(
        styles.toggle,
        isFirstActive && styles["toggle--active"],
        styles[`toggle--${resolvedTheme}`],
        className
      )}
    >
      <button
        type="button"
        className={styles.toggle__button}
        onClick={handleClickFirst}
      >
        One way
      </button>
      <button
        type="button"
        className={styles.toggle__button}
        onClick={handleClickSecond}
      >
        By the hour
      </button>
    </div>
  )
}

export default Toggle
