import { FunctionComponent } from "react"
import dynamic from "next/dynamic"
import { ReactDatePickerProps } from "react-datepicker"

import "react-datepicker/dist/react-datepicker.min.css"
import styles from "./styles.module.scss"

const ReactDatePicker = dynamic(() => import("react-datepicker"), {
  ssr: false,
})

const DatePicker: FunctionComponent<ReactDatePickerProps> = (props) => (
  <div className={styles.root}>
    <ReactDatePicker
      {...props}
      popperModifiers={[
        {
          name: "arrow",
          options: {
            padding: ({ popper, reference }) => ({
              right: Math.min(popper.width, reference.width) - 24,
            }),
          },
        },
      ]}
    />
  </div>
)

export default DatePicker
