import { FunctionComponent, useCallback, useEffect } from "react"
import { useRouter } from "next/router"
import { useForm, FieldValues, Controller } from "react-hook-form"
import dynamic from "next/dynamic"
import ReactGA from "react-ga4"

import DatePicker from "../Datepicker/Datepicker"
import Button, { VariantType } from "../Button/Button"
import Input from "../Input/Input"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import { isSameDay } from "../../../utils/time"

import styles from "./styles.module.scss"

const PlaceAutocompleteInput = dynamic(
  () => import("../PlaceAutocompleteInput/PlaceAutocompleteInput"),
  {
    ssr: false,
  }
)

const ORDER_DETAILS_URL = "/order/details"

interface HourseStepProps {
  resolvedTheme?: string
  nextStepUrl: string
}

const HoursStep: FunctionComponent<HourseStepProps> = ({
  resolvedTheme = "light",
  nextStepUrl,
}) => {
  const router = useRouter()
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm()

  useEffect(() => {
    if (typeof router.query.d === "string") {
      const [date, time] = router.query.d.split(" ")
      const [day, month, year] = date.split("/")
      const [hours, minutes] = time.split(":")
      const dateValue = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day),
        parseInt(hours),
        parseInt(minutes)
      )
      setValue("date", dateValue)
      setValue("time", dateValue)
    }
    if (typeof router.query.hours === "string") {
      setValue("hours", router.query.hours)
    }
  }, [router.query.d, router.query.hours, setValue])

  useEffect(() => {
    if (typeof router.query.from === "string") {
      setValue("from", {
        name: router.query.from,
        place_id: router.query.from_place_id,
      })
    }
  }, [router.query.from, router.query.from_place_id, setValue])

  const onSubmit = (values: FieldValues) => {
    const timeDate = new Date(values.time)
    const date = new Date(values.date)
    date.setHours(timeDate.getHours())
    date.setMinutes(timeDate.getMinutes())

    const formatedTime = `${date.getHours()}:${
      date.getMinutes() < 10 ? "0" : ""
    }${date.getMinutes()}`
    const formatedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`

    if (nextStepUrl !== ORDER_DETAILS_URL) {
      ReactGA.event("order_step_1_ride_details", {
        type: "by_the_hour",
      })
    } else {
      ReactGA.event("order_step_2_ride_details", {
        type: "by_the_hour",
        car: router.query.slug ? router.query.slug : "",
      })
    }

    const query = {
      ...router.query,
      from_place_id: values.from.place_id,
      from: values.from.name,
      hours: values.hours,
      time: date.getTime(),
      distance: 0,
      to: "",
      to_place_id: "",
      d: `${formatedDate} ${formatedTime}`,
    }

    router.replace(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true }
    )

    router.push({
      pathname: nextStepUrl,
      query,
    })
  }

  const filterPassedTime = useCallback(
    (time: Date) => {
      const currentDate = new Date()
      const dateValue = getValues("date")
      if (dateValue) {
        const isTheSameDay = isSameDay(currentDate, dateValue)
        if (isTheSameDay) {
          const selectedDate = new Date(time)
          currentDate.setHours(currentDate.getHours() + 1)
          return currentDate.getTime() < selectedDate.getTime()
        }
      }

      return true
    },
    [getValues]
  )

  const handleOnChangeDate = useCallback(
    (e: Date | null) => {
      if (e) {
        const currentDate = new Date()
        const dateValue = new Date(e)
        const isTheSameDay = isSameDay(currentDate, dateValue)
        if (isTheSameDay) {
          // TODO if time exist and is ok then do nothing
          setValue("time", "")
        }
      }
    },
    [setValue]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={styles.form__field}>
        <Controller
          control={control}
          name="from"
          rules={{
            validate: (value) => {
              if (!value) {
                return "Choose the pick up location from select box"
              }

              if (value && !value.place_id) {
                return "Choose the pick up location from select box"
              }

              return true
            },
          }}
          render={({ field }) => (
            <PlaceAutocompleteInput
              variant={resolvedTheme as VariantType}
              onPlaceSelected={field.onChange}
              label="Pick up location"
              id="top_form_from"
              placeholder="From"
              name="from"
              onChange={field.onChange}
              value={field.value?.name ?? field.value}
            />
          )}
        />
        {errors.from && <ErrorMessage>Pick up location required</ErrorMessage>}
      </div>
      <div className={styles.form__field}>
        <Controller
          control={control}
          name="hours"
          rules={{
            required: "Rental hours is required",
            validate: {
              positive: (value) => {
                if (Number.isNaN(value)) {
                  return "Rental hours should be a number"
                }
                if (Number(value) <= 0) {
                  return "Rental hours should be a positive number"
                }
              },
            },
          }}
          render={({ field }) => (
            <Input
              {...field}
              label="Rental hours"
              id="top_form_hours"
              variant={resolvedTheme as VariantType}
              type="number"
              min="4"
              placeholder="Hours, minimum 4"
            />
          )}
        />
        {errors.hours && (
          <ErrorMessage>{errors.hours.message as string}</ErrorMessage>
        )}
      </div>
      <div className={styles.form__section}>
        <div>
          <Controller
            control={control}
            name="date"
            rules={{ required: true }}
            render={({ field }) => (
              <DatePicker
                selected={field.value}
                onChange={(e) => {
                  handleOnChangeDate(e)
                  field.onChange(e)
                }}
                onFocus={(e: any) => e.target.blur()}
                minDate={new Date()}
                id="top_form_date"
                customInput={
                  <Input
                    label="Date of journey"
                    variant={resolvedTheme as VariantType}
                  />
                }
                placeholderText="Date"
              />
            )}
          />
          {errors.date && <ErrorMessage>Date is required</ErrorMessage>}
        </div>
        <div>
          <Controller
            control={control}
            name="time"
            rules={{ required: true }}
            render={({ field }) => (
              <DatePicker
                selected={field.value}
                onChange={field.onChange}
                onFocus={(e: any) => e.target.blur()}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                filterTime={filterPassedTime}
                dateFormat="h:mm aa"
                id="top_form_time"
                placeholderText="Time"
                customInput={
                  <Input label="Time" variant={resolvedTheme as VariantType} />
                }
              />
            )}
          />
          {errors.time && <ErrorMessage>Time is required</ErrorMessage>}
        </div>
        <p className={styles.form__hours}>
          * only <b>10</b> miles <b>per hour</b> including in town
        </p>
      </div>

      <Button
        type="submit"
        variant={resolvedTheme as VariantType}
        className={styles.form__button}
      >
        {nextStepUrl === ORDER_DETAILS_URL ? "Confirm" : "Get a quote"}
      </Button>
    </form>
  )
}

export default HoursStep
