import { FunctionComponent, useMemo } from "react"
import cn from "classnames"
import Link from "next/link"
import { useRouter } from "next/router"
import Image from "next/image"
import ReactGA from "react-ga4"

import { ICarsFields } from "@/types/contentful"
import UsersIcon from "@/assets/icons/users.svg"
import CaseIcon from "@/assets/icons/case.svg"
import Button from "../forms/Button/Button"

import styles from "./styles.module.scss"

interface CarSectionCarProps extends ICarsFields {
  count: number
}

const CarSectionCar: FunctionComponent<CarSectionCarProps> = ({
  image,
  nameOfCar,
  passengers,
  luggage,
  count,
  pricePerMile,
  pricePerHour,
  slug,
}) => {
  const router = useRouter()
  const href = useMemo(() => {
    const URL = "/order/location"
    return {
      pathname: URL,
      query: { ...router.query, slug },
    }
  }, [router, slug])

  const handleClick = () => {
    ReactGA.event("order_step_1_сar_select", {
      car: slug,
    })
  }

  return (
    <div
      className={cn(
        styles.cars__container_item,
        count === 1 && styles["cars__container_item--full"]
      )}
    >
      {image && (
        <Link
          href={`/cars/${slug}`}
          title={nameOfCar}
          className={styles.cars__image_link}
        >
          <Image
            className={styles.cars__container_image}
            src={image?.fields.file.url}
            alt={image?.fields.title}
            width={485}
            height={275}
            loading="lazy"
          />
        </Link>
      )}
      <Link
        href={`/cars/${slug}`}
        title={nameOfCar}
        className={styles.cars__name}
      >
        <h3>{nameOfCar}</h3>
      </Link>
      <div className={styles.cars__container_characteristics}>
        <p className={styles.cars__characteristics}>
          <UsersIcon />
          <span className={styles.cars__icon}>{passengers} passengers</span>
        </p>
        <p className={styles.cars__characteristics}>
          <CaseIcon />
          <span className={styles.cars__icon}>{luggage}</span>
        </p>
      </div>
      {count !== 1 && (
        <div className={styles.cars__footer}>
          <div className={styles.cars__price}>
            <p>
              <span className={styles.cars__price_pounds}>&#163;</span>
              <span className={styles.cars__price_amount}>
                {pricePerHour}
              </span>{" "}
              / hour
            </p>
            <p>
              <span className={styles.cars__price_pounds}>&#163;</span>
              <span className={styles.cars__price_amount}>
                {pricePerMile}
              </span>{" "}
              / mile
            </p>
          </div>
          <Link href={href} passHref legacyBehavior>
            <Button
              as="a"
              className={styles.cars__button}
              onClick={handleClick}
            >
              Get a quote
            </Button>
          </Link>
        </div>
      )}
    </div>
  )
}

export default CarSectionCar
